@font-face {
  font-family: "Autowide";
  src: local("Autowide-Regular"),
    url(./fonts/Audiowide-Regular.ttf) format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root > div.MuiBox-root.css-k008qs > header > div > span > span{
  background-color: unset;
}
