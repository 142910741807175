@font-face {
  font-family: "Autowide";
  src: local("Autowide-Regular"),
    url(../../../../fonts/Audiowide-Regular.ttf) format("truetype");
}

.heroContainer {
  min-height: 100vh;
  margin-top: -64px;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(45, 60, 77, 1) 0%,
    rgba(29, 31, 33, 1) 100%
  );
  overflow: hidden;
}

.heroSection {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroSection::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #1d1f21, transparent);
  z-index: 1000;
}

.heroSection img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.heroSection img#clouds {
  mix-blend-mode: screen;
}

.heroSection img#roadLights {
  opacity: 0;
  z-index: 1;
}

.heroSection img#icon {
  height: 404px;
  width: 400px;
  left: 50%;
  top: 50%;
  margin-top: -260px;
  margin-left: -200px;
  opacity: 0.4;
}

.titleContainer {
  position: absolute;
  color: #fff;
  white-space: nowrap;
  font-size: 2rem;
  z-index: 9;
  top: 50%;
  font-family: "Autowide";
  /* transform: skew(0deg, -30deg); */
  /* transform: rotateX(75deg); */
}
