.productImg {
  height: 100%;
  width: auto;
}

@media (max-width: 767px) {
  .productImg {
    height: auto;
    width: 100%;
  }
}
