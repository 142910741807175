@font-face {
  font-family: "Autowide";
  src: local("Autowide-Regular"),
    url(../../../../fonts/Audiowide-Regular.ttf) format("truetype");
}

.aboutSection {
  min-height: calc(80vh - 64px);
  position: relative;
  background-color: #1d1f21;
  color: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.bgImg {
  opacity: 0.2;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: -10%;
  margin-bottom: -10%;
  height: 100%;
}

@media (max-width: 767px) {
  .bgImg {
    display: none;
  }
}

.aboutContainer {
  position: relative;
}

.aboutTitle {
  font-family: "Autowide";
  font-size: 64px;
  margin: 0;
}
