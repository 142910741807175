.brand {
  font-size: 2rem;
  font-family: "Autowide";
}
.footerTitle {
  font-weight: 600;
  padding-bottom: 4px;
  border-bottom: 1px solid #808080;
}
.useful-link {
  list-style-type: none;
  padding-left: 0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
  letter-spacing: 0.00714em;
}

.useful-link-item {
  cursor: pointer;
  margin-bottom: 8px;
  color: #fff;
}

.useful-link-item:hover {
  color: #90aec6;
}

.useful-link-item a {
  text-decoration: none;
  color: #fff;
}

.useful-link-item a:hover {
  color: #90aec6;
}

.medium-icon {
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
}

.subscribe-input {
  margin-top: 8px;
  height: 30px;
  width: 65%;
  border: none;
  padding: 8px;
  border-radius: 5px 0 0 5px;
}

.subscribe-button {
  margin-top: 8px;
  height: 30px;
  width: 35%;
  background-color: #90aec6;
  color: #fff;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
