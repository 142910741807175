.contactSection {
  min-height: calc(100vh - 64px);
  position: relative;
  background-color: #1d1f21;
  color: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.contactSection::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #1d1f21, transparent);
  z-index: 1000;
}

.bgImgContact {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.contactContainer {
  position: relative;
}

.contactTitle {
  font-family: "Autowide";
  font-size: 52px;
  margin: 0;
  margin-bottom: 24px;
}
